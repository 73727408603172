/* eslint-disable */
if (!localStorage.getItem('search-and-bookmark-data')) {
  localStorage.setItem('search-and-bookmark-data', JSON.stringify({
    pages: {
      key: 'title',
      data: [
                {
          title: 'Main',
                    route: 'main',
                              icon: 'HomeIcon',
                              isBookmarked: '',
                  },
                {
          title: 'Партнеры',
                    route: 'partners',
                              icon: 'UserIcon',
                              isBookmarked: '',
                  },
                {
          title: 'Администраторы системы',
                    route: 'superadmins',
                              icon: 'StarIcon',
                              isBookmarked: '',
                  },
                {
          title: 'Настройки',
                              icon: 'SettingsIcon',
                            },
              ]
    },
  }))
}
// prettier-ignore
export default [
    {
    title: 'Main',
        route: 'main',
            icon: 'HomeIcon',
          },
    {
    title: 'Партнеры',
        route: 'partners',
            icon: 'UserIcon',
          },
    {
    title: 'Администраторы системы',
        route: 'superadmins',
            icon: 'StarIcon',
          },
    {
    title: 'Настройки',
            icon: 'SettingsIcon',
            children: [{"title":"\u0422\u0438\u043f \u043f\u043e\u0441\u0442\u0443\u043f\u043b\u0435\u043d\u0438\u044f","route":"receipttypes","icon":"CircleIcon","isBookmarked":false},{"title":"\u0420\u043e\u043b\u0438","route":"roles","icon":"CircleIcon","isBookmarked":false}],
      },
  ]
