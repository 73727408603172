import { ref, computed, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import axios from '@axios'
import store from '@/store'

export default function useVerticalNavMenu(props) {
  // ------------------------------------------------
  // isVerticalMenuCollapsed
  // ------------------------------------------------
  const isVerticalMenuCollapsed = computed({
    get: () => store.state.verticalMenu.isVerticalMenuCollapsed,
    set: val => {
      store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', val)
    },
  })

  // ------------------------------------------------
  // collapseTogglerIcon
  // ------------------------------------------------
  const collapseTogglerIcon = computed(() => {
    if (props.isVerticalMenuActive) {
      return isVerticalMenuCollapsed.value ? 'unpinned' : 'pinned'
    }
    return 'close'
  })

  const isMouseHovered = ref(false)

  const updateMouseHovered = val => {
    isMouseHovered.value = val
  }

  const toggleCollapsed = () => {
    isVerticalMenuCollapsed.value = !isVerticalMenuCollapsed.value
  }

  const { router, route } = useRouter()
  const brancheSelected = ref(null)

  const branches = ref([])
  const fetchBranches = () => {
    axios
      .get('branchs', {
        params: { partner_id: route.value.params.partner_id },
      })
      .then(response => {
        const { data } = response.data

        branches.value = data

        if (route.value.params.branche_id) {
          // eslint-disable-next-line
          brancheSelected.value = branches.value.filter(b => b.id === parseInt(route.value.params.branche_id))[0]
        }
      })
  }

  watch([brancheSelected], () => {
    if (brancheSelected.value && brancheSelected.value.id !== parseInt(route.value.params.branche_id, 10)) {
      if (route.value.name === 'main' || route.value.name === 'branchs') {
        router.push({
          name: 'branche',
          params: {
            branche_id: brancheSelected.value.id,
          },
        })
      } else {
        router.replace({
          name: route.value.name,
          params: {
            ...route.value.params,
            branche_id: brancheSelected.value.id,
          },
        })
      }
    }
  })

  watch([route], () => {
    if (route.value.name === 'branchs') {
      brancheSelected.value = null
    }
  })

  return {
    isMouseHovered,
    isVerticalMenuCollapsed,
    collapseTogglerIcon,
    toggleCollapsed,
    updateMouseHovered,
    brancheSelected,
    branches,
    fetchBranches,
  }
}
